import React from 'react';
import SolutionPage from '../../components/SolutionPage';

const data = {
    heroSection: {
        label: {
            title: 'Smart Gauge & Meters', 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/smart-gauges-label-logo.png', 
            imgDimensions: {
                width: '0.875rem', 
                height: '0.875rem'
            }, 
            mobileImgDimensions: {
                width: '0.6rem', 
                height: '0.625rem' 
            }
        }, 
        heading: [
            {
                text: 'Convert your existing machine gauge into a ', 
                textColor: 'black'
            },
            {
                text: 'Smart Gauge', 
                textColor: 'blue-800'
            }
        ],  
        content: "Get real-time readings and anomaly alerts, without replacing any equipment.", 
        videoSrc: 'https://www.youtube.com/embed/I_uZObS6cok'
    }, 
    problemsSection: {
        heading: 'Problems with traditional gauges', 
        content: [
            {
                title: ['Supervision'], 
                text: 'Traditional gauges require constant human monitoring to detect faults', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/supervision-icon.png'
            },
            {
                title: ['Delays'], 
                text: 'No mechanism to raise a real-time fault alert, which results in costly response delay', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/delays-icon.png'
            },
            {
                title: ['Data Loss'], 
                text: 'Impossible to perform failure analysis because meter readings are not retained', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/data-loss-icon.png'
            }
        ]   
    }, 
    outcomesSection: {
        heading: 'Industry 4.0 without costly replacements', 
        content: [
            {
                title: 'Timely Response', 
                text: 'Receive failure alerts in real-time which enables you to take instant action', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/timely-response-icon.png', 
                imgDimensions: {
                    width: '5.12rem', 
                    // height: '2.93rem'
                    height: '3.48rem'
                }
            },
            {
                title: 'Lower costs', 
                text: 'Remove the need for costly human supervision of meters on your factor floor', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/lower-cost-icon.png', 
                imgDimensions: {
                    width: '3.72rem', 
                    height: '3.48rem'
                    // height: '2.93rem'
                }
            },
            {
                title: 'Data logging', 
                text: 'Every reading is preserved in a database which enables performance analysis', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/document-audit-icon.png', 
                imgDimensions: {
                    width: '3.43rem', 
                    // height: '3.56rem'
                    height: '3.48rem'
                }
            },
        ]
    },
    automateSection: {
        heading: ['Remotely monitor all your gauges with', 'easy to setup software'], 
        content: [
            {
                id: '01', 
                title: 'Customized for your existing equipment', 
                text: [
                    "Loopr's camera-based solution is customized to recognize all possible readings of your machine's analog or digital meter",
                    'Works with any type of measurement: temperature, pressure, flow rate, pH, torque, ammeter, voltmeter, tachometer and more'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/smart-gauges-automate-01.png'
            },
            {
                id: '02', 
                title: 'Digital readings in real-time', 
                text: [
                    'Current reading of a gauge is extracted in real-time and displayed on a remote screen continously, turning your traditional gauge into a connected and smart gauge',
                    "Get readings at a central location without requiring manual supervision of gauges on your shop floor"
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/smart-gauges-automate-02.png'
            },
            {
                id: '03', 
                title: 'Fault management', 
                text: [
                    'Define anomaly levels for each gauge. Receive an instant alert if meter reading cross certain values, so you can respond without any delays',
                    ' Every reading and fluctuation is stored in your database to enable root cause analysis on failure'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/smart-gauges-automate-03.png'
            },
        ]
    }, 
    platformSection: true
}

export default function SmartGaugesPage(){
    return <SolutionPage data={data} />
}